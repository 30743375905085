<template>
  <div>
    <v-card class="mt-9">
      <v-data-table dense
                    :headers="headers"
                    :items="data_items"
                    :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Branch Data</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearch"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.id }}
            </td>
            <td>
              {{ item.date_registered }}
            </td>
            <td>
              {{ item.branch_code }}
            </td>
            <td>
              {{ item.room_counter }}
            </td>
            <td>
              {{ item.address }}
            </td>
            <td>
              {{ item.contact_no }}
            </td>
            <td>
              {{ item.contact_person }}
            </td>
            <td class="text-center">
              <v-icon
                class="mr-2"
                color="success"
                @click="view_rooms(item)"
              >
                {{icons.mdiEyeCircle}}
              </v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="is_view_rooms" max-width="90%">
      <branch-rooms :key="this.key" :selected_branch="this.selected_branch"/>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiCheck, mdiClose
    , mdiAccountOff
    , mdiAccountCheck
    , mdiPlus
    , mdiEyeCircle
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import BranchRooms from '@/views/register_interface/branch_component/BranchRooms'

  const initialState = () => {
    return {
      key: 0,
      is_view_rooms: false,
      selected_branch: {},
      data_items: [],
      search: '',
      headers: [
        {text: 'ID', value: 'id', sortable: true},
        {text: 'Date Registered', value: 'date_registered', sortable: true},
        {text: 'Branch Code', value: 'branch_code', sortable: true},
        {text: 'Room Counter', value: 'room_counter', sortable: true},
        {text: 'Address', value: 'address', sortable: true},
        {text: 'Contact #', value: 'contact_no', sortable: true},
        {text: 'Contact Person', value: 'contact_person', sortable: true},
        {text: 'View Rooms', align: 'center', value: 'view_rooms', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
      BranchRooms,
    },
    setup() {
      return {
        icons: {
          mdiCheck,
          mdiClose,
          mdiAccountOff
          , mdiAccountCheck
          , mdiPlus
          , mdiEyeCircle
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('branch_information', ['list_of_branch']),
      initialize_data() {
        const data = new FormData()
        data.append('is_active', 1);
        this.list_of_branch(data)
          .then(response => {
            this.data_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      view_rooms(item) {
        this.key++
        this.selected_branch = item
        this.is_view_rooms = true
      },
    },
  }
</script>
